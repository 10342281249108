import React from 'react'
import Button from 'src/components/shared/Button'

const CenteredButton = ({text, onClick}) => {
  return (
    <div style={ styles.container }>
      <Button
        {...{onClick}}
      >
        { text }
      </Button>
    </div>
    )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 20
  }
}

export default CenteredButton
