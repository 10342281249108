import React from "react"
import BlogPageTemplate from 'src/components/content/BlogPageTemplate'
import CenteredButton from 'src/components/content/CenteredButton'

import MagnifyingGlassImage from 'src/images/content/marten-newhall-magnifying-glass-700px.jpg'
import ConversationImage from 'src/images/content/christina-wocintechchat-talking-at-table-700px.jpg'

import { Link, navigate } from "gatsby"

import { buildURL } from 'src/utilities/functions'

const title = 'Connect with the Hiring Manager with these 5 Steps'
const subtitle = 'Does it seem like you’re getting nowhere reaching an actual human being with your resume?  Here’s our recommendations on getting even further in the process and reaching the Hiring Manager.'
const date = 'September 23, 2021'

const seoDescription = 'Resumes being read by humans still happens. How to increase your chances of having your resume read by a Hiring Manager.'

const landingPageURL = '/'
const resumeToolURL = '/resume-templates'

const Content = ({location}) => {
  return (
    <div>
      <h2>1. Research and Connect with the Hiring Manager</h2>
        In our world of <a href="https://www.linkedin.com/">LinkedIn</a> and <a href="http://google.com">Google</a> search, our ability to learn who the Hiring Manager is, what they currently do, and what they’re interests are outside of business, is nearly limitless.

        <em>Interests</em> is the key and LinkedIn is your friend.

        <br/>
        <figure class="figure mb-7 mt-7">
          <img class="figure-img img-fluid rounded lift lift-lg"
            src={MagnifyingGlassImage}
            alt="Connect with the Hiring Manager with these 5 steps"
            width="700"
            height="467"
          />
          <figcaption class="figure-caption text-center">
            Connect with the Hiring Manager with these 5 steps
          </figcaption>
        </figure>

        <h3>Prelude to your Resume - example of Hiring Manager interests</h3>
        The Hiring Manager loves to cook, is a long-time volunteer with Habitat for Humanity, can’t get enough of the Chicago Bulls basketball team, and went to college at Princeton.

        In all likelihood, you’ll have one of these in common with them.  If you don’t, with a bit of research, it’s fast and easy to become knowledgeable about a topic.

        Why do all this?
        <h3>Developing a a friendly, ongoing connection with the Hiring Manager</h3>

        Your mission is to become friends by having something in common with them outside of business. One of the biggest mistakes in any relationship is communicating with a person <em>only when you need something</em>. Successful friendships or relationships of any kind are based on reaching out periodically just to say “hi” or “I just read about Habitat for Humanity, what was the most challenging build you’ve been on?”


        <h3>Where to find information about the Hiring Manager</h3>

        Scroll down their profile in LinkedIn to areas including Education, Licenses & Certifications, Volunteer Experience, Skills & Endorsements, Accomplishments, and Interests (particularly “Groups”).

        To view this information, make sure that you’re connected with the person. If you can’t because your network doesn’t extend wide enough, reach out to a person that they are connected to within 2 degrees of connection. And if that doesn’t work, spend the money on a month worth of LinkedIn Sales Navigator - it will allow you to send a direct message to a person regardless of your current or potential connection status.


        <h3>Hot Introduction to the Hiring Manager</h3>
        We’ve heard this before, but it’s worth saying again: If you already know someone at the company, ask them to introduce you to the Hiring Manager. There’s nothing like an endorsement to start a connection off on the right foot.


        <h3>Having a Conversation</h3>
        Pick one of the things that you now have in common with the Hiring Manager and send a one sentence message to them with a connect request. “Hi James, I noticed that you’re a Bulls fan. What did you think about yesterday’s game?”

        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: If your request is approved, and it probably will be because most people on LinkedIn don’t discriminate too much about who they connect with, send a brief message thanking them for the connection and repeating your question if they didn’t initially respond. The reason for the repeated question? LinkedIn makes it difficult for the Hiring Manager to say “yes” to a connection and respond to a question. Your entire point of connecting is having an ongoing, but not time-consuming, conversation. Just enough so that they know that you’re genuinely interested.</p>
        <p className='mt-3 ml-6'>Be a good friend and don’t forget to send your occasional tip through a message: “I just read about this great recipe for tacos in the <em>Financial Times</em> of all places! What do you think?” (Include the link).</p>
        <CenteredButton
          text='Build Your Resume Today!'
          onClick={
            () => navigate(
              buildURL(
                landingPageURL,
                {
                  referring_page: location.pathname,
                  referring_cta: 'Build Your Resume Today!',
                }
              )
            )
          }
        />
        <figure class="figure mb-7 mt-7">
          <img
            class="ml-auto mr-auto figure figure-img img-fluid rounded lift lift-lg"
            src={ConversationImage}
            alt="Connect with the Hiring Manager with these 5 steps"
            width="700"
            height="467"
          />
          <figcaption class="figure-caption text-center">
            Connect with the Hiring Manager with these 5 steps
          </figcaption>
        </figure>

        <h2>2. Write your Remarkable Cover Letter <em>for</em> the Hiring Manager</h2>
        If you were the Hiring Manager which cover letter would you want to receive:
        <ul>
           <li>Option 1: “I’m interested in being hired for the position of Advisor at your company.”</li>
           <li>Option 2: “What’s the best way to become a gourmet chef? The reason why I ask is I’ve started my journey to becoming a chef in my own kitchen for fun and I read that you’re an expert at making Crab Cakes. What’s your secret?”</li>
        </ul>
        The purpose of the first sentence is to convince the Hiring Manager to read the next sentence. Each sentence has the same purpose as the previous one.

        Once you’ve established interest in the first brief paragraph, the next and last paragraph should be details about the company and specifically how you can help the Hiring Manager achieve their goals.

        These goals shouldn’t be the ones listed in the “About the Job Section.” It should be goals that you’ve learned from the Hiring Manager's profile, company overview, and messages you’ve shared with the Hiring Manager.

        You should be answering questions like: “What are your top 2 financial goals for the year?” “If you could change anything about your job to make it easier, what would you do?”

        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: Find a hardcopy magazine or book that relates to the Hiring Manager's interests.  Highlight a particular article or paragraph with a post-it-note.  Include it with your cover letter and resume when you send it to them.  Send your package to them using either UPs or FedEx because these shipping services will give you visibility as well as the capability to track when and who signed for the package - something useful for your follow-up communications.</p>
        <p className='mt-3 ml-6'><em><strong>Bonus</strong></em>: Most of your competition won’t do any of this, so you’ll be remembered in a positive light.</p>

        <h2>3. Research the Position and Company</h2>
        This is going to be brief: A company’s website, LinkedIn page, and <a href="https://www.morningstar.com/">Morningstar</a> summary (if they’re a publicly held company - one that’s listed on a stock exchange) will contain just about all the information that you need.
        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: For a publicly held company, it’s worth reading their annual report. These are usually available as a download file on the website under “Investor Relations” or by searching on the <a href="http://www.sec.gov">U.S. Securities and Exchange Commission’s website: SEC.gov</a>. It’s well worth your time to read it and use the information in your cover letter and resume.</p>

        <h1>4. Your Resume - brief, fact-driven content</h1>

        Trying to change the company culture isn't the type of content needed in your resume, unless that's part of the job description. It is the time to learn exactly what the company does and how they do it.

        Then do the same thing with the position for which you’re applying.

        Read and re-read every line related to the job and answer every explicit and implicit question or statement within your draft resume. At this stage, don’t worry about the length or format - just write.


        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: It’s ok to write “I have no idea how to address this question" in your draft resume as a note to yourself.</p>
        In a second document, list every job and position you’ve held with up to 4 lines or bullet points
        <ul>
           <li>Company, job title, dates of employment</li>
           <li>General description of the company and what you did for them.</li>
           <li>Example 1 of a specific achievement: “I increased revenue 30% by writing blogs that were Search Engine Optimized (SEO).”</li>
           <li>Example 2 of a specific achievement: “I reduced cost 20% by automating invoicing.”</li>
        </ul>
        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: Be specific, use numbers, be brief.</p>
        Then use your second document to answer in the first paragraph (bullet point style or a single short paragraph) the top one or two questions asked in the job description.
        <p className='mt-3 ml-6'><em><strong>Pro Tip</strong></em>: The purpose of the very first section of your resume is to ensure that your resume is not discarded.  You have 7 seconds to convince the person reading it that you understand what  they’ve written in the job description and you have at least two items covered.</p>

        <h1>5. Visual appeal - presentation is everything</h1>
        Technically, the difference between an Apple iPhone and Google device is vanishingly small. But Apple is renowned for their presentation skills to the point that people post YouTube videos about unboxing their latest phone.

        Same goes for your resume.

        If it doesn’t look good and isn’t easy to read, your resume will be discarded.

        Here’s where we can help. <a href={landingPageURL}>Learn more</a>.
        <p className='mt-3 ml-6'><em><strong>Bonus</strong></em>: If all these steps seem daunting, we’re here to help. Use our automated system to download your LinkedIn profile directly into our <a href={resumeToolURL}>resume tool</a>. There’s nothing like saving time while creating a beautiful resume that’s going to get read!</p>
        <CenteredButton
          text='Get Started!'
          onClick={
            () => navigate(
              buildURL(
                landingPageURL,
                {
                  referring_page: location.pathname,
                  referring_cta: 'Get Started!',
                }
              )
            )
          }
        />
    </div>
    )
}

const BlogPage = ({location}) => {

  return (
    <BlogPageTemplate
      {...{
        title,
        subtitle,
        date,
        seoDescription,
        location,
      }}
      content={<Content {...{location}}/>}
    />
  )
}

export default BlogPage

