import React from "react";
import {Helmet} from "react-helmet";

const SupportHelmet = () => {

  // not using zendesk right now

  return null

  // return (
  //   <Helmet>
  //     <script
  //       id="ze-snippet"
  //       src="https://static.zdassets.com/ekr/snippet.js?key=44afa9eb-f7f2-44a5-9ee3-aeb2ce8e78c1"
  //       type="text/javascript"
  //     />
  //   </Helmet>)
}

export default SupportHelmet
