import React, {useEffect} from "react"
import { Link, navigate } from "gatsby"
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { trackPage, trackEvent, getPropertiesFromLocation,} from 'src/utilities/analytics'
import SupportHelmet from 'src/components/SupportHelmet'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon as RSFacebookIcon,
  LinkedinIcon as RSLinkedInIcon,
  RedditIcon,
  TwitterIcon as RSTwitterIcon,
} from "react-share";
import Logo from 'src/images/diy-logo.png'
import 'src/styles/blog-page-template.css'


// not using these for now, using react-share's images
// import FacebookIcon from 'src/images/icons/social/facebook.svg'
// import TwitterIcon from 'src/images/icons/social/twitter.svg'
// import InstagramIcon from 'src/images/icons/social/instagram.svg'
// import LinkedInIcon from 'src/images/icons/social/linkedin.svg'

// react-share email share not working and neither is mailto: link
// maybe my browser setting?
// TODO:
// ratings? rate article see what content is good


const pageName = 'Blog Post'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  `

const trackShare = ({type, url, title}) => {
  trackEvent('career_advice_share_click', {
      title,
      type,
      url,
    })
}

const AuthorDateAndShare = ({author, authorAvatarSrc, date, title, subtitle, }) => {

  const base_url = `${process.env.BASE_URL}`
  const location = useLocation()

  const url = location.href

  // for test, since localhost is no good
  // const url = `https://nextlevelresume.co${location.pathname}`

  return (
    <div className="row align-items-center py-5 border-top border-bottom">
      <div className="col-auto">

        {/*<!-- Avatar -->*/}
        <div className="avatar avatar-lg">
          <img src={authorAvatarSrc || Logo} alt="..." className={`avatar-img ${authorAvatarSrc ? 'rounded-circle' : ''}`}/>
        </div>

      </div>
      <div className="col ml-n5">

        {/*<!-- Name -->*/}
        <h6 className="text-uppercase mb-0">
          {author || 'NextLevelResume Editorial Team'}
        </h6>

        {/*<!-- Date -->*/}
        <time className="font-size-sm text-muted" dateTime={date}>
          {`Published on ${date}`}
        </time>

      </div>
      <div className="col-auto">

        {/*<!-- Share -->*/}
        <span className="h6 text-uppercase text-muted d-none d-md-inline mr-4">
          Share:
        </span>

        {/*<!-- Icons -->*/}
        <ul className="d-inline list-unstyled list-inline list-social">

          <li className="list-inline-item list-social-item mr-3">
            <FacebookShareButton
              url={url}
              quote={title}
              beforeOnClick={ () => trackShare({type: 'facebook', url, title}) }
            >
              <RSFacebookIcon className='list-social-icon'/>
            </FacebookShareButton>
          </li>
          <li className="list-inline-item list-social-item mr-3">
            <LinkedinShareButton
              url={url}
              title={title}
              summary={subtitle}
              beforeOnClick={ () => trackShare({type: 'linkedin', url, title}) }
            >
              <RSLinkedInIcon className='list-social-icon'/>
            </LinkedinShareButton>
          </li>
          <li className="list-inline-item list-social-item mr-3">
            <TwitterShareButton
              url={url}
              title={title}
              beforeOnClick={ () => trackShare({type: 'twitter', url, title}) }
            >
            <RSTwitterIcon className='list-social-icon'/>
            </TwitterShareButton>
          </li>
          <li className="list-inline-item list-social-item mr-3">
            <RedditShareButton
              url={url}
              title={title}
              beforeOnClick={ () => trackShare({type: 'reddit', url, title}) }
            >
              <RedditIcon className='list-social-icon'/>
            </RedditShareButton>
          </li>
          <li className="list-inline-item list-social-item mr-3">
            <EmailShareButton
              url={url}
              subject={title}
              beforeOnClick={ () => trackShare({type: 'email', url, title}) }
            >
              <EmailIcon className='list-social-icon'/>
            </EmailShareButton>
          </li>
        </ul>

      </div>
    </div>
    )
}

const Header = ({
  title,
  subtitle,
  author,
  authorAvatarSrc,
  date,
}) => {
  return (
    <section className="pt-8 pt-md-11 w-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">

            {/*<!-- Heading -->*/}
            <h1 className="display-4 text-center">
              {title}
            </h1>

            {/*<!-- Text -->*/}
            <p className="lead mb-7 text-center text-muted">
              {subtitle}
            </p>

            <AuthorDateAndShare
              {...{
                author,
                authorAvatarSrc,
                date,
                title,
              }}
            />

          </div>
        </div>{/* <!-- / .row -->*/}
      </div>{/* <!-- / .container -->*/}
    </section>
    )
}

const Footer = ({
  author,
  authorAvatarSrc,
  date,
  title,
  subtitle,
}) => {
  return (
    <section className="pt-8 pt-md-11 w-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">

            <AuthorDateAndShare
              {...{
                author,
                authorAvatarSrc,
                date,
                title,
                subtitle,
              }}
            />

          </div>
        </div>{/* <!-- / .row -->*/}
      </div>{/* <!-- / .container -->*/}
    </section>
    )
}

const Body = ({htmlContent, content}) => {
  return (
    <section className="pt-1 pt-md-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">
            { content }
          </div>
        </div>
      </div>
    </section>
    )
}

// not using this for now
const DangerousHTMLContainer = ({htmlContent}) => {
  const htmlContentObject = {
    __html: htmlContent
  }
  return (
    <div dangerouslySetInnerHTML={htmlContentObject} />
    )
}

// author and authorAvatarSrc optional, defaults to NextLevelResume Editorial Team
const BlogPageTemplate = ({title, subtitle, author, authorAvatarSrc, date, htmlContent, content, seoDescription, location}) => {
  useEffect(() => {
    const urlProperties = getPropertiesFromLocation(location)
    trackPage(
      pageName,
      {
        ...urlProperties,
        title,
      }
    )
  }, [])
  return (
    <Layout
      onSignInSuccess={() => navigate('/resumes')}
    >
      <SEO
        title={title}
        description={seoDescription}
      />
      <SupportHelmet/>
      <AnalyticsHelmet/>
      <Container>
        <Header
          {...{
            title,
            subtitle,
            author,
            authorAvatarSrc,
            date,
          }}
        />
        <Body
          {...
            {content}
          }
        />
        <Footer
          {...{
            title,
            subtitle,
            author,
            authorAvatarSrc,
            date,
          }}
        />
      </Container>
    </Layout>
  )
}

export default BlogPageTemplate
